import React, { useEffect, useState } from "react";
import styles from "../../styles/curiosidad.module.scss";
import { IsMobileDevice } from "../../utils/functions";
import ArtistPage from "./ArtistPage";

export default function Curiosidad({ data }) {
    // console.log(data);    
    const allArtistas = data.allArtistas.nodes;    
    const sortedArtistas = allArtistas.sort((a, b) => a.order - b.order);

    const [isMobileDevice, setIsMobileDevice] = useState(false);
    useEffect(() => {
        setIsMobileDevice(IsMobileDevice());
    }, []);

    const mobileTitle = (
        <h1 className={styles.titleA}>
            ART <br />
            FAMILY <br />
            <span className={styles.titleB}>UTOPIA</span>
        </h1>
    );

    const desktopTitle = (
        <h1 className={styles.titleA}>
            ART FAMILY
            <span className={styles.titleB}> UTOPIA</span>
        </h1>
    );

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {isMobileDevice ? mobileTitle : desktopTitle}               
                {sortedArtistas.map((artista) => (
                    <ArtistPage
                        key={artista.name}
                        name={artista.name}
                        description={artista.description}
                        art={artista.art}
                        photo={artista.photo}
                        media={artista.media}
                        playlist={artista.playlist}
                        active={artista.active}
                        order={artista.order}
                    />
                ))}
            </div>
        </div>
    );
}
