import React, { useEffect } from "react";
import styles from "../styles/home.module.scss";
import Seo from "../components/seo";
import loadable from "@loadable/component";
import Footer from "../components/Footer";
import Curiosidad from "../components/Sections/Curiosidad";
import { graphql } from "gatsby";

const Header = loadable(() => import("../components/Header"));
export default function CuriosidadPage({ data }) {

    useEffect(() => {
        window.dataLayer.push({
            brand: "Cerveza 27",
            Zone: "SAZ",
            country: "ARG",
            city: "CABA",
            campaign: `${
                window.location.search.includes("?cmp=")
                    ? window.location.search.split("?cmp=")[1].split("&")[0]
                    : ""
            }`,
            cms: "0",
            event: "pageView",
            language: "es",
            login: false,
            pageName: "Curiosidad",
            pageType: "Basic page",
            SiteType: "Brand",
            product: "NA",
            sku: "NA",
            userUid: "",
            url: "https://cerveza27.com/curiosidad",
            url_campaign: `${
                window.location.search.includes("utm_campaign=")
                    ? window.location.search.split("utm_campaign=")[1]
                    : ""
            }`,
            Step_number: "",
        });
    }, []);

    return (
        <div className={styles.superContainer}>
            <Seo title="Curiosidad" />
            {/* <AgeGate /> */}
            <Header isHideLanding={true} />
            <section id="curiosidad">
                <Curiosidad data={data} />
            </section>
            <Footer isHideLanding={false} />
        </div>
    );
}

export const data = graphql`
    query curiosidades {
        allArtistas {
            nodes {
                id
                art
                description
                media
                name
                photo
                playlist
                order
                active
            }
        }
    }
`;
