import React from "react";
import styles from "../../styles/curiosidad.module.scss";

const ArtistPage = ({
    name,
    description,
    art,
    photo,
    media,
    playlist,
    active,
}) => {

    return (
        <div className={styles.artistCont}>
            <div className={styles.artistInfo}>
                <img
                    // src={require(`./../../images/${photo}.png`).default}
                    src={photo}
                    className={styles.imageCont}
                    id={photo}
                    alt="artist"
                ></img>
                <p className={styles.name}>{name}</p>
                <div className={styles.descCont}>
                    <p className={styles.description}>
                        {description.replace(".", ".\n")}
                    </p>
                    <p className={styles.art}>{art}</p>
                </div>
            </div>
            
            <div className={styles.artistMedia}>
                {active === "playlist" ? (
                    <iframe
                        style={{ borderRadius: "10px" }}
                        className={styles.playlistCont}
                        src={playlist}
                        width="100%"
                        height="360"
                        frameBorder="0"
                        allowFullScreen=""
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                        loading="lazy"
                    ></iframe>
                ) : (
                    <img
                        // src={require(`./../../images/${media}.png`).default}
                        src={media}
                        className={styles.mediaCont}
                        id={styles.media}
                        alt="artist media"
                    ></img>
                )}
            </div>
        </div>
    );
};

export default ArtistPage;
